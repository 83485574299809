/* Overlay styling */
.HowWeWorkModal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
}

/* Modal content styling */
.HowWeWorkModal-content {
  position: relative;
  background: white;
  padding: 30px;
  max-width: 850px;
  width: 90%;
  height: auto;
  max-height: 90vh;
  border-radius: 20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
}

/* Opening animation - Slide up */
.HowWeWorkModal-content.opening {
  animation: slideUp 0.3s ease-out forwards;
}

/* Closing animation - Slide down */
.HowWeWorkModal-content.closing {
  animation: slideDown 0.3s ease-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

/* Close button styling */
.HowWeWorkModal-closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
  transition: color 0.3s ease;
}

.HowWeWorkModal-closeButton:hover {
  color: #ff6347;
}

/* Close button icon styling */
.HowWeWorkModal-closeIcon {
  width: 24px;
  height: 24px;
}

/* Title styling */
.HowWeWorkModal-title {
  font-size: 24px;
  font-weight: 900;
  color: rgb(14, 57, 143);
  text-align: center;
  margin-bottom: 20px;
}

/* Container for side-by-side boxes */
.HowWeWorkModal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
}

/* Box styling */
.HowWeWorkModal-box {
  box-sizing: border-box;
  border-radius: 15px;
  padding: 20px;
  width: 45%;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.HowWeWorkModal-box:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
}

/* Image container */
.HowWeWorkModal-imageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Larger image with softer shadows */
.HowWeWorkModal-image {
  width: 150px;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

/* Text container */
.HowWeWorkModal-textContainer {
  text-align: left;
}

/* Subtitle styling */
.HowWeWorkModal-subtitle {
  font-size: 18px;
  font-weight: 800;
  color: rgb(14, 57, 143);
  margin-bottom: 16px;
  
  letter-spacing: 1px;
}

/* List styling */
.HowWeWorkModal-list {
  padding-left: 20px;
  list-style: none;
}

/* List item styling */
.HowWeWorkModal-listItem {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
}

/* Check mark icon styling */
.HowWeWorkModal-checkMark {
  color: #4caf50;
  font-size: 22px;
  margin-right: 10px;
}

/* Responsive adjustments for tablets and smaller screens */
@media (max-width: 1024px) {
  .HowWeWorkModal-container {
    flex-direction: column;
    align-items: center;
  }

  .HowWeWorkModal-box {
    width: 80%;
    margin-bottom: 20px;
  }

  .HowWeWorkModal-title {
    font-size: 22px;
  }

  .HowWeWorkModal-subtitle {
    font-size: 16px;
  }
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
  .HowWeWorkModal-container {
    flex-direction: column;
    align-items: center;
  }

  .HowWeWorkModal-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .HowWeWorkModal-image {
    width: 120px;
  }

  .HowWeWorkModal-title {
    font-size: 20px;
  }

  .HowWeWorkModal-subtitle {
    font-size: 16px;
  }

  .HowWeWorkModal-listItem {
    font-size: 14px;
  }
}
