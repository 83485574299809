/* Modal styling */
.bank_modal {
    width: 90%;
    /* Default width */
    max-width: 500px;
    /* Maximum width */
    height: auto;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-height: 90%;
    /* Maximum height to prevent overflowing */
    overflow-y: auto;
    /* Enable scrolling if content exceeds the height */
}

.bank_modal-overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* Add padding to ensure modal has space on smaller screens */
}

@media (min-width: 768px) {
    .bank_modal {
        width: 50%;
        /* Take 50% of the width on larger screens */
        max-width: 600px;
        /* Maximum width for larger screens */
        max-height: 80%;
        /* Adjust maximum height */
    }
}

/* Media query for small mobile screens */
@media (max-width: 480px) {
    .bank_modal {
        width: 95%;
        /* Take almost full width on mobile */
        padding: 15px;
        /* Decrease padding for smaller screens */
        max-width: none;
        /* No maximum width on small screens */
        max-height: 85%;
        /* Limit the height on mobile */
    }
}

/* Modal header styling */
.bank_modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.bank_modal-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #0E398F;
}

/* Close button styling */
.bank_modal-close {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

/* Modal body styling */
.bank_modal-body {
    text-align: left;
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
}

/* Bank details list styling */
.bank_modal-list {
    padding-left: 1.5rem;
    margin-top: 10px;
}

.bank_modal-list-item {
    margin-bottom: 10px;
}

/* Adding animations */
@keyframes bank_modal-fade-in {
    0% {
        opacity: 0;
        transform: translateY(20%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.bank_modal {
    animation: bank_modal-fade-in 0.5s ease forwards;
}

.fixed-size-modal {
    width: 600px;
    height: 500px;
    overflow-y: auto;
  }
  
  .bank_modal-overlay {
    background-color: rgba(0, 0, 0, 0.75); /* Optional overlay styling */
  }
  .bank_modal-body {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .bank_modal-body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
  