:root {
  --main-color: #0E398F;
  --secondary-color: rgb(247, 183, 13);
}

::selection {
  color: #fff;
  background: var(--secondary-color);
}


.login-bg {
  position: relative;
}

.divColor {
  background-color: #fff9c5;
}

.divColor2 {
  background-color: #f5f8fc;
}

.divColor3 {
  background-color: #f9f6f1;
}

.login-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

.previewDiv {
  border: 2px solid #D3D3D3;
  background-color: white;
  padding: 6px 7px;
  margin-bottom: 20px;
  border-radius: 4px;
  height: auto;
  width: 400px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);

}

.success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.contact-form-sec {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 10px 10px rgba(117, 114, 114, 0.255) !important;
  z-index: 3;
  border-radius: 10px;
}


.banner-contact-form {
  width: 300px;
}

.banner-contact-form input,
.banner-contact-form textarea {
  border-bottom: 1px solid #707070;
  border-radius: 0px;
  margin-right: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 20px;
}

.banner-contact-form select {
  border-bottom: 1px solid #707070;
  background-color: rgba(255, 255, 255, 0.8);
}


/* Ensure that the .login-bg container hides overflow */
.login-bg {
  overflow: hidden;
}

.change-pw-bg {
  position: relative;
}

.change-pw-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/addSubadmin.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .change-pw-bg container hides overflow */
.change-pw-bg {
  overflow: hidden;
}


.create-ac-bg {
  position: relative;
}

.create-ac-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/create-ac-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .create-ac-bg container hides overflow */
.create-ac-bg {
  overflow: hidden;
}

.create-profile-bg {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* black with 50% opacity */
}

.paypal-button-container {
  /* position: absolute; */
  z-index: 10; /* Lower than the modal */
  /* bottom: 10px; Adjust if needed */
}

/* Higher z-index for modal */
.modal-container_paypal {
  position: relative;
  z-index: 1000; /* High enough to appear above other elements */
}

/* Ensure overlay has high z-index */
.ReactModal__Overlay {
  z-index: 999; /* Higher than PayPal button */
}


.create-profile-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/create-profile-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .create-profile-bg container hides overflow */
.create-profile-bg {
  overflow: hidden;
}

.forget-pw-bg {
  position: relative;
}

.forget-pw-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/forget-pw-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .forget-pw-bg container hides overflow */
.forget-pw-bg {
  overflow: hidden;
}


.add-bg-bg {
  position: relative;
}

.add-bg-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/add-bd-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .add-bg-bg container hides overflow */
.add-bg-bg {
  overflow: hidden;
}

.formControlDropDown {
  margin: 0.5rem;
  width: 900px;
  background-color: white;
}


.liveFeedsHeader {
  position: fixed;
  top: 250px;
  right: 0;
  z-index: 999;
  width: 500px;
}

.displayTour {
  display: block;

}

/* .mask {
  color: #454e5c;
  opacity: 0.9;
} */
.mask {
  color: #454e5c;
  opacity: 0.9;
  transition: all 0.3s ease !important;
  /* Smooth transitions */
}

.step-content {
  display: flex;
  flex-direction: column;
  /* Arrange items in a column */
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  text-align: center;
  /* Center text */
  height: 100%;
  /* Adjust height if needed */
}

.emoji-large {
  font-size: 3rem;
  /* Adjust size as needed */
}



/* Blur effect on the body when the tour is active */
.tour-active body {
  filter: blur(5px);
  /* Adjust the blur intensity */
  transition: filter 0.3s ease;
}

/* Ensure the tour highlights stay clear */




.verify-addr-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/verify-addr-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.imgLeftMargin{
  margin-right: 2rem;
}


/* Animations Styles */


@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/* Fade In Left Animation */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade In Right Animation */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade In Top Animation */
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade In Bottom Animation */
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animations to elements as needed */
.fade-in-left {
  animation: fadeInLeft 0.5s ease-out;
}

.fade-in-right {
  animation: fadeInRight 0.5s ease-out;
}

.fade-in-top {
  animation: fadeInTop 0.5s ease-out;
}

.fade-in-bottom {
  animation: fadeInBottom 0.5s ease-out;
}


/* Slide In Left Animation */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide In Right Animation */
@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide Out Right Animation */
@keyframes slideOutRight {
  from {
    opacity: 0;
    transform: translateX(0);
  }

  to {
    opacity: 1;
    transform: translateX(100%);
  }
}

/* Slide In Up Animation */
@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Slide In Down Animation */
@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animations to elements as needed */
.slide-in-left {
  animation: slideInLeft 0.5s ease-out;
}

.slide-in-right {
  animation: slideInRight 0.5s ease-out;
}

.slide-out-right {
  animation: slideOutRight 0.5s ease-out;
}

.slide-in-up {
  animation: slideInUp 0.5s ease-out;
}

.slide-in-down {
  animation: slideInDown 0.5s ease-out;
}



/* Rise In Right Animation */
@keyframes riseInRight {
  from {
    opacity: 0;
    transform: translateX(50%) translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

/* Rise In Up Animation */
@keyframes riseInUp {
  from {
    opacity: 0;
    transform: translateX(50%) translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

/* Rise In Down Animation */
@keyframes riseInDown {
  from {
    opacity: 0;
    transform: translateX(50%) translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

/* Apply the animations to elements as needed */
.rise-in-left {
  transition: 0.4s;
}

.rise-in-right {
  animation: riseInRight 0.5s ease-out;
}

.rise-in-up {
  animation: riseInUp 0.5s ease-out;
}

.rise-in-down {
  animation: riseInDown 0.5s ease-out;
}



.pd-bn
{
  padding-left: 2rem;
}


.loading {
  z-index: 1;
  position: relative;
  background-color: white;
  border: 2px solid black;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.editor-container {
  width: 80%;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ccc;
}

.gjs-frame img {
  max-width: 100%;
  height: auto;
}

.txt-style-1 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.txt-style-2 {
  color: #fff;
  font-size: 46px;
  font-weight: 500;
  font-family: 'DM Serif Display', serif;
  line-height: 3.2rem;
}

.txt-style-3 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 2rem;
  padding-block: 30px;
}

.txt-style-4 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 2rem;
  padding-block: 30px;
}

.txt-style-5 {
  color: #5E5E5E;
  font-size: 30px;
  font-weight: 500;
  line-height: 2rem;
  padding-block: 20px;
}

.txt-style-6 {
  color: #5E5E5E;
  font-size: 20px;
  font-weight: 400;
  line-height: 2rem;
  padding-block: 20px;
}

.txt-style-7 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  line-height: 2rem;
  padding-block: 40px;
}

.para-style-1 {
  font-size: 14px;
}

.donor-info {
  background-color: #efefef;
}



.btn-style-1 {
  background-color: var(--main-color);
  font-weight: 700;
  transition: 0.4s;
}

.btn-style-1:hover {
  background-color: #091b3d;
  font-weight: 700;
}

.login-btn {
  background-color: #fff;
  color: var(--secondary-color);
  padding-inline: 40px;
  font-weight: 700;
  transition: 0.4s;
}

.login-btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
  font-weight: 700;
}

.btn-style-2 {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 5px 4px 2px #d8d8d879;
  font-weight: 700;
  transition: 0.4s;
}

.btn-style-2:hover {
  background-color: #091b3d;
  color: #fff;
  font-weight: 700;
}


.btn-style-3 {
  background-color: var(--secondary-color);
  border-radius: 20px;
  color: #000;
  box-shadow: 0px 5px 4px 2px #d8d8d879;
  font-weight: 400;
  transition: 0.4s;
  border: 1px solid var(--secondary-color);
  font-family: 'Poppins', sans-serif;

}

.btn-style-3:hover {
  background-color: #fff;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn-style-4 {
  background-color: var(--secondary-color);
  border-radius: 5px;
  color: #000;
  font-weight: 400;
  transition: 0.4s;
  border: 1px solid var(--secondary-color);
  font-family: 'Poppins', sans-serif;
}

.btn-style-4:hover {
  background-color: #fff;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}


.rbc-time-slot {
  /* background-color: whitesmoke; */
  background-color: #ddecec;
  /* color: #74a4c3; */
  color: #000;
}

/* .rbc-timeslot-group .rbc-time-slot{
  background-color: rgb(146, 105, 105);
} */
/* 
.rbc-timeslot-group .rbc-time-slot:hover{
  background-color: rgb(225, 199, 199);
} */





.rbc-time-slot:hover {
  background-color: rgb(240, 161, 161);
}

.rbc-allday-cell {
  display: none
}

.rbc-allday-cell:hover {
  background-color: rgb(240, 161, 161);
}

.rbc-event,
.rbc-background-event {
  /* padding :0px !important; */
  border: none !important;

}

.rbc-time-content .rbc-time-column .rbc-timeslot-group .rbc-time-slot:hover {
  background-color: rgb(225, 199, 199) !important;
  z-index: 2;

}



#default-sidebar {
  height: 100vh; /* Makes the sidebar occupy full viewport height */
}

.paymentBlock {
  height: 350px;
}

.paymentBlock1 {
  height: 400px;
}


.rbc-event-label {
  display: none;
}




.rbc-time-content .rbc-time-column .rbc-timeslot-group .rbc-time-slot:hover {

  background-color: rgb(225, 199, 199) !important;
  z-index: 2;
  /* position: relative; Ensure it has positioning */

}

.rbc-events-container {
  width: 100%;
  pointer-events: auto;
  /* Allow pointer events */
  z-index: 1;

}

/* .rbc-events-container .rbc-current-time-indicator{
  width :100%;

   background-color:  red;

} */


.blurred-with-counter {
  position: relative;
  /* Allow absolute positioning of ::after */
  filter: blur(2px);
  /* Apply blur effect */
}

/* 
.blurred-with-counter::after {
  content: attr(data-counter);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(17, 16, 16);
} */




.image-wrapper {
  position: relative;
  /* Allow absolute positioning of the counter text */
  display: inline-block;
}



.counter-text {
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 86%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Translate to center */
  font-size: 1.5rem;
  /* Adjust font size as desired */
  font-weight: bold;
  /* Use bold text */
  color: white;
  /* Text color for visibility */
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: add a background color for better contrast */
  padding: 3.2rem 2.5rem;
  /* Optional: add padding around the text */
  border-radius: 0.25rem;
  /* Optional: add a border-radius */
  z-index: 1;
  /* Ensure it appears on top of the image */
}


.blurred-image {
  filter: blur(2px);
  /* Apply blur effect */
}


.video-reel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 10px;
  gap: 10px;
}

/* Video reel item styles */
.video-reel-item {
  flex: none;
  width: 250px;
  /* Adjust as needed */
  scroll-snap-align: start;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
}

.video-reel-item video {
  width: 100%;
  height: 400px;
  border-radius: 8px;
}

.video-reel-item p {
  margin-top: 8px;
  font-size: 14px;
}


.reel-view {
  width: 100%;
  /* Adjust this to the desired width of the reel view */
  height: 500px;
  /* Adjust this to the desired height of the reel view */
  overflow: hidden;
  /* Hide overflow content */
}

.video-container {
  width: 100%;
  /* Adjust as needed */
  height: 500px;
  /* Adjust as needed */
  position: relative;
  /* Make the container relative to position the overlay */
  overflow: hidden;
  /* Hide overflow content */
}

.channel-name-overlay {
  position: absolute;
  /* Positioning the overlay element absolutely */
  bottom: 30px;
  /* Align the overlay at the bottom */
  left: 0;
  /* Align the overlay at the left */
  padding: 5px;
  /* Add padding for a cleaner look */
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: white;
  /* Text color */
  font-size: 14px;
  /* Font size for the text */

  align-items: center;
}

.text-value {
  display: block;
  /* Ensure block display */
  font-size: 14px;
  /* Adjust the font size if necessary */
  color: #ffffff;
  /* Adjust text color as needed */
  width: auto;
  /* Width can be adjusted as per requirement */
  line-height: normal;
  /* Adjust line height if needed */
  break-words: break-all;
  /* Allow words to break if necessary */
}

.channel-name-overlay img {
  margin-right: 8px;
  /* Add margin to the right of the image */
}

.channel-name-overlay span {
  font-weight: 600;
  /* Make the text bold */
  color: #fefefe;
  /* Adjust text color */
}

.channel-info {
  display: flex;
  /* Use Flexbox to align the items */
  align-items: center;
  /* Vertically center the items */
  margin-bottom: 4px;
  /* Add some margin below the channel info */
}

.text-value {
  font-size: 12px;
  /* Adjust font size as needed */
  color: #ffffff;
  /* Adjust text colo r as needed */
}

.reel-icons {
  position: absolute;
  /* Absolute positioning */
  bottom: 10px;
  /* 10px from the bottom */
  right: 10px;
  /* 10px from the right */
  display: flex;
  /* Use flexbox layout */
  flex-direction: column;
  /* Arrange icons vertically */
  gap: 10px;
  /* Space between icons */
  z-index: 10;
  /* Higher z-index to appear on top */
}

/* Icon button styles */
.icon-button {
  background: none;
  /* No background */
  border: none;
  /* No border */
  cursor: pointer;
  /* Pointer cursor */
  color: white;
  /* Adjust color */
  font-size: 24px;
  /* Adjust size */
}

.btn-style-5 {
  background-color: #fff;
  border-radius: 5px;
  color: var(--main-color);
  font-weight: 400;
  transition: 0.4s;
  border: 1px solid var(--main-color);
  font-family: 'Poppins', sans-serif;
}

.btn-style-5:hover {
  background-color: var(--main-color);
  color: #fff;
  border: 1px solid var(--main-color);
}




.checked\:bg-pink-500:checked {
  --tw-bg-opacity: 1;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.checked\:before\:bg-pink-500:checked::before {
  background-color: var(--main-color);
}

.margin-res{

  margin-top:  3rem;
}

/* Scrollbar Styling */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}



/* Verify Addr Styling */
.verify-addr {
  min-height: 120vh;
}

.verify-addr-form {
  background-color: #fff;
  padding: 60px 80px;
  border-radius: 20px;
  border-color: #606060;
  z-index: 999;
  width: 90%;
  margin: 0 auto;
}

/* 
.verify-addr-form::before {
  content: "";
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 30px;
  background-image: url("/public/assets/images/verify-addr-bg-box.png");
  background-repeat: no-repeat;
} */

.verify-addr-form Input {
  background-color: #F6F6F6;
  border-color: #F6F6F6;
  border-radius: 10px;
  color: #606060;
}

/* Success profile styling */

.success-message {
  background-color: #fff;
  padding: 60px 120px;
  border-radius: 20px;
  border-color: #606060;
  z-index: 999;
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Loader Bg color */

.loader-bg-color {
  /* background-color: var(--secondary-color); */
  background: linear-gradient(-45deg, #f0b433, #f0b433, #f0b433, #e07801, #e07801, #e07801);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

.progress-bg-color {
  background-color: #5FCC00;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.primary-bg-color {
  background-color: var(--main-color);
}

.primary-text-color {
  color: var(--main-color);
}


.money-details-accordion:nth-child(2n+1) {
  background-color: #EFEFEF !important;
}

.campaign-blocks {
  width: 50%;
  margin-top: 50px;
  margin-left: 250px;
  margin-right: 250px;
}

.custWidth{
  width: 50rem;
}




/* Landing page banner section styling */

/* .landing-page-banner-section {
  background-image: url("/public/assets/images/landing-page-banner.png");
  min-height: 70vh;
  background-size: cover;
  padding-inline: 100px;
  background-position: 0px -35px;
} */
.landing-page-banner-section {
  background-image: url("/public/assets/images/b7.png");
  min-height: 90vh;
  background-size: cover;
  padding-inline: 100px;
  background-position: 0px -30px;
}



.landing-page-banner-section-new {
  background-image: url("/public/assets/images/banner 6.png");
  min-height: 80vh;
  background-size: cover;
  padding-inline: 39px;
  background-repeat: no-repeat;
  background-position: -11px -21px;




}





.banner-image {
  width: 35%;
  height: 20%;
  margin-top: 40px;
}

.home_btn-style-1 {
  border-radius: 20px;
  width: 150px;
  /* Increase the width */
  background-color: #d0ccb9;
  /* Change the background color */
  color: black;
}


.banner-text-1 {
  color: #000;
  font-size: 24px;
}

.banner1-text-1 {
  color: #f3eded;
  font-size: 24px;
}



.banner-text-2 {
  color: #000;
  font-size: 4.6rem;
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
}

.banner-text-3 {
  color: #000;
  font-size: 3.0rem;
  font-family: 'DM Serif Display', serif;
  font-weight: 300;
}

.yellow-texture-bg {
  background-image: url("/public/assets/images/yellow-texture.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px 20px;
}

.counter-text-1 {
  position: relative;
  color: #000;
  font-size: 46px;
  padding-inline: 30px;
  font-family: 'Poppins', sans-serif;
}


.counter-text-2 {
  position: relative;
  font-family: 'Poppins', sans-serif;

}

.counter-text-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: -160px;
  right: 0;
  bottom: 0;
  background-image: url("/public/assets/images/yellow-blue-texture.png");
  background-size: 150px;
  background-repeat: no-repeat;
  z-index: -1;
  animation: fadeInRight 1.0s ease-out;
}


/* Icon Box Styling */
.icon-box {
  background-color: #F0F0F0;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: 0.4s;
}

.font-family-poppins {
  font-family: 'Poppins', sans-serif;
}

.icon-box:hover {
  border: 1px solid var(--secondary-color);
}


/* Live feed section styling  */
.live-feed-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EEEEEE;
}

.donation-boxLiveFeed {
  /* width: 54%; */
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 20px;

}

.donation-boxLiveFeedSocial {
  /* width: 54%; */
  background-color: #f0eeee;
  border-radius: 20px;
  padding-bottom: 20px;
  border: 1px solid #afaeae
}

.donation-description-boxLiveFeed {
  padding-inline: 11px;
  text-align: left;
}

.donation-description-boxLiveFeed .description-text {
  color: #000;
  padding-right: 14px;
  font-size: 15px;
}

.donation-box {
  /* width: 400px; */
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 30px;
}

/* .donation-boxBrowseFund {
  width: 400px;
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 30px;
} */
.donation-boxBrowseFund {
  width: 85%; /* Ensure boxes take full width of grid columns */
  max-width: 400px; /* Prevent excessive width on large screens */
  margin: auto; /* Center boxes */
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 30px;
}

.padding-x{
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-container {
  display: grid;
  column-gap: 1rem; /* Horizontal gap between grid items */
  row-gap: 1rem; /* No vertical gap */
}


.donation-description-box {
  padding-inline: 25px;
  text-align: left;
}

.donation-description-box .description-text {
  color: #000;
  padding-right: 18px;
  font-size: 18px;
}

.analytics {
  /* width: 400px; */

  background-color: #fff;
  border: 1px solid #e5e7eb;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 7px 10px !important;
  ;
  border-radius: 20px !important;
  padding-bottom: 30px;
}

.border-box {
  border: 1px solid #e5e7eb;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 7px 10px !important;
  border-radius: 20px !important;

}

.margin-res{

  margin-top:  5rem;
}

.analytics-box {
  padding-inline: 10px;
  text-align: left;
}

.analytics-box .description-text {
  color: #000;
  font-size: 18px;
}

.channel-list {
  /* width:600px; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.channel-item {
  /* display: flex; */
  align-items: center;
  background-color: rgba(197, 195, 195, 0.04);
  padding: 20px;
  border: 1px solid #e5e7eb;
  /* box-shadow:  0px 0px 3px 4px #4541412f; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 7px 10px !important;

}

.textremSize {
  font-size:  9rem ;
}

.ellipsis-icon {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 0;
  color: rgb(61, 60, 60);
  font-size: 20px;
}

.checkIcon {
  width: 25px;
  height: 25px;
  position: relative;
  right: 100px;
  top: 0;

}

.options {
  position: relative;
}

.tooltip {
  position: absolute;
  top: calc(100% + 5px);

  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 7px 10px !important;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 215px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.options:hover .tooltip {
  opacity: 1;
  /* Show the tooltip on hover */

}


.tooltip.open {
  opacity: 1;
  /* Show the tooltip with transition */
}

.tooltip button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}

.ellipsis-icon {
  cursor: pointer;
}






.donation-info-text {
  color: #0049B7;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
}

.donation-info-text-box {
  color: #0049B7;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
}

.donation-info-numbers {
  color: #000;
}

.progress-bar-green {
  width: 100%;
  height: 10px;
  background-color: #fff;
  /* Set your desired background color */
  border-radius: 5px;
  border: 0.5px solid #5FCC00;
}

.progress-bar-fill-green {
  height: 100%;
  border-radius: 5px;
  background-color: #5FCC00;
}

.counter-overlay {
  background-color: var(--main-color);
  border-radius: 20px;
  position: absolute;
  width: 100px;
  height: 100px;
}

.blue-box-text {
  line-height: 20px !important;
}

.orange-txt {
  color: var(--secondary-color);
}

.top-fundraiser-sec {
  background-image: url("/public/assets/images/fundraiser-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.top-footer-sec {
  background-color: #0f3796;
  color: #fff;
}

.footer {
  background-color: #062265;
  padding-block: 50px;
}

.bottom-footer {
  background-color: #00174D;
  padding-block: 20px;
}


.list-footer li {
  display: flex;
  gap: 10px;
}


.list-footer li:hover {
  color: var(--secondary-color) !important;
}

.excite-text {
  color: #ff0004;
}


/* Create Fundraiser Styling */

.create-fundraiser-sec {
  width: 100%;
}

.fundraiser-box {
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: 0.4s;
  border: 2px solid transparent;
  width: 400px;
  /* height: 6rem; */
}

.fundraisers-box {
background-color : white;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: 0.4s;
  border: 2px solid transparent;
  width: 400px;
  /* height: 6rem; */
}

.hide-lg
{
  display:none;
}

.fundraiser-box img {
  border-radius: 10px;
  /* height: 10px; */
}

.fundraiser-box:hover {
  border: 2px solid var(--main-color);
  background-color: rgba(233, 233, 233, 0.301);
  cursor: pointer;

}

.fundraiser-info-text {
  color: #0049B7;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
}

.fundraiser-info-numbers {
  color: #000;

}


/* Fundraiser for other form  */

.fundraiser-for-other-form {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}

.fundraiser-for-other-form label {
  width: 50%;
  font-size: 14px;
  margin-block: auto;
  font-weight: 500;
}


/* Fundraisr-sec */

.fundraiser-banner-section {
  background-image: url("/public/assets/images/fundraiser-bg.png");
  /* background-size: 100% 62%;
  background-repeat: no-repeat; */

}

.fundraiser-banner-section .slick-slide {
  margin-top: 20px;
  padding-block: 30px;
}


/* .fundraiser-slider-box {
  width: 42rem;
  height: 40rem;
  object-fit: cover;
} */



.fundraiser-slider-box {
    width: 46rem;
    height: 33rem;
    object-fit: cover;
    object-position: top;
}


.fundraiser-banner-section .slick-dots {
  position: absolute;
  bottom: 55px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  list-style: none;
  text-align: center;
}


.slick-dots li button:before {
  font-size: 18px !important;
  content: '〇' !important;
  color: var(--main-color) !important;

}

.slick-dots li.slick-active button:before {
  font-size: 28px !important;
  content: '◉' !important;
  color: var(--main-color) !important;
}

.fundraiser-banner-section .slick-arrow {
  display: none !important;
}


.share-fb-div {
  background-color: #3B5998;
  padding: 10px 20px;
  color: #fff;
  gap: 10px;
  transition: 0.4s;
}

.share-fb-div:hover {
  background-color: #243355;
  color: #fff;
  cursor: pointer;
}

.share-twitter-div {
  background-color: #00ACEE;
  padding: 10px 20px;
  color: #fff;
  gap: 15px;
  transition: 0.4s;
}

.share-twitter-div:hover {
  background-color: #03638a;
  color: #fff;
  cursor: pointer;
}

.slick-arrow {
  display: none !important;
}


.imgDim{
  width: 18rem;
  height: 20rem;
}




/* About us Page Styling */

.about-page-banner-section {
  background-image: url("/public/assets/images/aboutUSNew.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}


.sidebar {
  background-color: #393939;
  border-top-right-radius: 30px;
}


.donationSidebar {
  background-color: #dee2e6;
  border-top-right-radius: 30px;
  height: 2000px;

}


.user-img-box {
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 3px 4px #0000002f;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 
.react-datepicker {
  display: flex;
  flex-direction: row;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
} */


div.react-datepicker {
  display: flex !important;
  /* flex-direction: row !important; */
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #aeaeae !important;
  border-radius: 0.3rem !important;
  z-index: 1000;
}

div.react-datepicker__time-container {
  position: static !important;
  border-left: none !important;
  margin-left: 3px !important;
  /* Optional: Add some space between date and time pickers */
}

div.react-datepicker__time {
  border-radius: 0 !important;
}

div.react-datepicker__time-box {
  width: 100% !important;
}

div.react-datepicker__time-list {
  height: auto !important;
  max-height: 195px !important;
  /* Adjust the height as needed */
}


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}


.social-icon-box svg {
  filter: grayscale(50);
  transition: 0.2s;
  cursor: pointer;
}

.social-icon-box svg:hover {
  filter: grayscale(0);
}

.mantine-vl6xmj {
  z-index: 0 !important;
}

/* How We Work Styling */

.how-we-work-banner-section {
  background-image: url("/public/assets/images/HWWNew.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.how-we-work-modal-section {
  background-image: url("/public/assets/images/hww-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
}

.cust-height {
  height: 100vh;
  /* 80% of viewport height */
}

.verify-user1 {
  width: 100%;
  max-width: 100%;
  /* Ensure no overflow occurs */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

/* Mantine table Custom styling */
.verify-user .mantine-kq5u63 {
  display: none;
}

.verify-user thead th {
  background-color: #EFEFEF !important;
}

.verify-user tbody tr:nth-child(even) td {
  background-color: #EFEFEF !important;
}


.verify-user .mantine-a5v3r0 {
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.verify-user1 .mantine-kq5u63 {
  display: none;
}

.verify-user1 thead th {
  background-color: #EFEFEF !important;
}

.verify-user1 tbody tr:nth-child(even) td {
  background-color: #EFEFEF !important;
}


.verify-user1 .mantine-a5v3r0 {
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.mantine-Table-root {}

.nav-item {
  border-bottom: 3px solid #fff;
  /* padding-bottom: 10px; */
}

.active-link {
  color: var(--main-color);
  padding-bottom: 10px;
  border-bottom: 3px solid var(--main-color);
}


.after-arrow::after {
  content: url('/public/assets/images/arrow.svg');
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 35px;
  margin-top: -30px;
}

.after-arrow-hows::after {
  content: url('/public/assets/images/arrow.svg');
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 291px;
  margin-top: 23px;
}



.banner-contact-form {
  width: 300px;
}

.banner-contact-form input,
.banner-contact-form textarea {
  border-bottom: 1px solid #707070;
  border-radius: 0px;
  margin-right: 15px;
  margin-top: 20px;
}

.banner-contact-form select {
  border-bottom: 1px solid #707070;
}


.hww {
  background-color: #FAFAFA;
}

.contact-us-banner {
  background-image: url("/public/assets/images/support1.jpg");
  background-size: cover;
  background-position: center;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  margin-bottom: 10px;
}

.pagination-item {
  padding: 0 19px;
  height: 66px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 7.43;
  font-size: 19px;
  min-width: 34px;
}

.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.pagination-item .arrow::before {
  position: relative;
  content: '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-item.disabled {
  pointer-events: none;
}

.pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-padding {

  padding: 15px;
}

.custom-datepicker {
  font-size: 16px;
  /* Set the desired font size */
  width: 700px;
  /* Set the desired width */
}



.how-we-work-modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.pieChart-Style {
  width: 1100px;
  height: 600px;
}

.bannerDivMargin {
  margin-left: 200px;
}


.banner-container {
  position: relative;
}

/*   
  .banner-image {
    width: 100%;
    height: auto;
  } */

.text-content {

  z-index: 2;
}

.divleftImg {
  margin-left: 65px;
}

.custom-row-layout {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.container1 {
  display: flex;
  flex-wrap: wrap;
}
.hamburger-menu {
  display: none;
}

/* 

.formenq-container {
  background-color: #f04f23;
  color: white;
  padding: 40px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  border-radius: 8px;
}

.formenq-title {
  font-size: 2rem;
}

.formenq-description {
  font-size: 1rem;
}

.formenq {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formenq-input,
.formenq-textarea,
.formenq-button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  margin-top: 10px;
}

.formenq-textarea {
  height: 100px;
  resize: none;
}

.formenq-button {
  padding: 12px;
  background-color: white;
  color: #f04f23;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.formenq-button:hover {
  background-color: #ff7043;
}

.input-container {
  display: flex;
  gap: 10px;
} */


@media only screen and (min-width: 768px) and (max-width: 1190px) {

 
  .padding-x{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .donation-boxNew {
    width: 100%; /* Full width for smaller screens */
    margin: 20px 0;
}

  .custWidth{
    width: 50rem;
  }

  .imgMarginLeft {
    margin-left: 100px;
  }
  .imgLeftMargin{
    margin-right: 0px;
  }

  .divMarginLeft {
    margin-left: 10px;
  }

  .container1>div {
    flex: 0 0 50%;
    /* Two divs per row */
  }

  .custom-row-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* 2 equal columns for layout */
    grid-gap: 1rem;
    /* Gap between search bar and buttons */
  }

  .search-bar {
    grid-column: 1 / 3;
    /* Search bar spans the entire width */
  }

  .buttons-group {
    display: flex;
    justify-content: flex-start;
    /* Buttons are aligned horizontally */
  }

  .button-item {
    margin-left: 10px;
  }



  .title-section {
    margin-bottom: 0;
    text-align: left;
    flex: 1;
  }

  .search-buttons-section {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 2;
  }

  .search-input {
    margin-right: 1rem;
    width: auto;
    flex: 1;
  }

  .button-group {
    justify-content: flex-end;
    width: auto;
    flex: 1;
  }

  .verify-user1 {
    padding-right: 1px;
    padding-left: 1px;
  }

  .displayTour {
    display: none;

  }

  .paymentBlock {
    height: auto;
  }

  .paymentBlock {
    height: auto;
  }

  .divleftImg {
    margin-left: 0px;
  }

  .bannerDivMargin {
    margin-left: 90px;
  }

  .banner-image {
    width: 40%;
    height: 28%;
    margin-top: 30px;
    position: relative;
    top: 200px;
    right: 10px;

  }

  .banner-contact-form {
    width: 100%;
  }

  .banner-contact-form {
    width: 100%;
  }


  .landing-page-banner-section-new {
    background-image: url("/public/assets/images/banner 6.png");
    min-height: 35vh;
    background-size: cover;
    padding-inline: 30px;
    padding-top: 10px;
    background-position: -56px 14px;
    background-repeat: no-repeat;
  }

  .campaign-blocks {
    width: 90%;

    margin: auto;
  }

  .pieChart-Style {
    width: 500px;
    height: 250px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: auto;
  }

  .cust-height {
    height: 90vh;
  }

  .how-we-work-modal {
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translateX(-50%);
  }


  .divHidden {
    display: none
  }
}


@media only screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {

  .padding-x{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .landing-page-banner-section-new {
    background-image: url("/public/assets/images/banner 6.png");
    min-height: 35vh;
    background-size: cover;
    padding-inline: 30px;
    padding-top: 10px;
    background-position: -160px 14px;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {

  .padding-x{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }
  .landing-page-banner-section-new {
    background-image: url("/public/assets/images/banner 6.png");
    min-height: 35vh;
    background-size: cover;
    padding-inline: 30px;
    padding-top: 10px;
    background-position: -200px 14px;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 1200px) {
  .padding-x{
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .liveFeedsHeader {
    display: none;
  }

  .imgMarginLeft {
    margin-left: 100px;
  }

  .displayTour {
    display: none;

  }

  .divHidden {
    display: none
  }





  .how-we-work-modal-section {
    background-image: url("/public/assets/images/hww-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
  }

}


@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    /* Adjust as needed */
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.5s ease-out forwards;
}

/* Responsive Styling for mobile screens */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .pd-bn
  {
    padding-left: 4rem;
  }
  .padding-x{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid-container {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
   
  .imgDim{

    width: 18rem;
    height: 20rem;

  }

  .margin-res{

    margin-top:  5rem;
  }

  .hide-lg
  {
    display:block;
  }
  .custWidth{
    width: 15rem;
  }

  .divMarginLeft {
    margin-left: 0;
  }

  .container1>div {
    flex: 0 0 50%;
    /* One div per row */
  }

  .divleftImg {
    margin-left: 0px;
  }

  .text-content {
    z-index: 2;

  }

  .bannerDivMargin {
    margin-left: 95px;
  }

  .banner-image {
    width: 54%;
    height: 22%;
    position: absolute;
    bottom: 190px;
    right: 1px;
    margin-top: 15px;
    z-index: 1;

  }

  .divHidden {
    display: none
  }

  .campaign-blocks {
    margin-top: 20px !important;
    width: 90%;
    margin: auto;
  }

  .checkIcon {
    width: 20px;
    height: 20px;
    position: relative;
    right: 22px;
    top: 0;
  }

  .liveFeedsHeader {
    display: none;
  }

  .displayTour {
    display: none;

  }

  .how-we-work-modal {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* black with 50% opacity */
  }

  .cust-height {
    height: 100vh;
    /* 80% of viewport height */
  }

  .how-we-work-modal-section {
    background-image: url("/public/assets/images/hww-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
  }

  .landing-page-banner-section {
    background-image: url("/public/assets/images/landing-page-banner.png");
    min-height: 50vh;
    background-size: cover;
    padding-inline: 20px;
    padding-top: 5px;
    background-position: center -45px;
    background-repeat: no-repeat;
  }




  .landing-page-banner-section-new {
    background-image: none;
    /* Hide the background image */
    min-height: 70vh;
    /* Adjust height */
    padding-top: 0px;
    display: block;
    /* Reset display to block for content stacking */
  }

  .landing-page-banner-section-new::after {
    content: "";
    display: block;
    background-image: url("/public/assets/images/m3.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 44vh;
    /* Height for the background image */
    width: 95%;
    position: absolute;
    /* bottom: 50px; */
    left: 10px;
    z-index: -1;
  }


  .banner-text-1 {
    font-size: 15px;
  }

  .banner-text-2 {
    font-size: 1.8rem;
  }

  .banner-text-3 {
    font-size: 1.8rem;
  }

  .btn-style-3 {
    font-size: 12px;
  }

  .counter-text-1 {
    font-size: 36px;
    padding-inline: 10px;
    padding-bottom: 10px;
  }

  .counter-text-2::before {
    left: -110px;
    background-size: 110px;
  }

  .txt-style-7 {
    font-size: 22px;
    padding-block: 20px;
  }

  .donation-description-box {
    padding-inline: 30px;
    text-align: left;
  }

  .btn-style-1 {
    font-size: 11px;
  }

  .txt-style-3 {
    color: #000;
    font-size: 22px !important;
    padding-block: 10px;
  }

  .truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .verify-addr-form {
    padding: 40px 20px;
    width: 97%;
    margin-top: 50px;
  }

  .success-message {
    background-color: #f1f1f1;
    padding: 30px;
    border-radius: 20px;
    border-color: #606060;
    z-index: 999999999 !important;
    width: 95%;
    margin-top: 195%;
    text-align: center;
  }

  .fundraiser-slider-box {
    width: 90%;
    height: 50%;
    margin-inline: auto;
    object-fit: cover;
  }

  .fundraiser-banner-section {
    background-size: 100% 84%;
  }

  .fundraiser-box {
    margin-inline: 20px;
  }

  .fundraiser-banner-section .slick-slide {
    padding-block: 10px;
  }


  .about-page-banner-section {
    padding-top: 40px;
    background-size: cover;
    background-position: center;
    height: 200px;
  }

  .sidebar {
    border-top-right-radius: 0px;
  }

  .donationSidebar {
    border-top-right-radius: 0px;
  }

  .how-we-work-banner-section {
    background-size: cover;
    padding-top: 50px;
    height: 380px;
  }


  .formControlDropDown {
    margin: 0.3rem;
    width: 300px;
    background-color: white;
  }


  /* *********************** */
  .pagination-container {
    display: flex;
    list-style-type: none;
  }

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
  }

  .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
  }

  .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  .pagination-item.selected {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .pagination-item .arrow::before {
    position: relative;
    content: '';
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  }

  .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
  }

  .pagination-item .arrow.right {
    transform: rotate(45deg);
  }

  .pagination-item.disabled {
    pointer-events: none;
  }

  .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }

  .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
  }

  .pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .webkit-center {
    text-align: -webkit-center;
  }
}

.webkit-centerForAnalytics {
  text-align: -webkit-center;
}





.sidebar span {
  display: inline-block;
  width: 100%;
  text-align: left;
  /* background-color: white; */
  padding: 10px;
}

.sidebar .selected {
  background-color: #e2e8f0;
  /* Equivalent to bg-gray-200 */
}

/* Ensure that the body cannot scroll */
.no-scroll {
  overflow: hidden;
}

/* New home css */
.backgroudHomeImage {
  background-image: url("/public/assets/images/landing-page-banner.png");
  background-repeat: round;
}

.bannerButton {
  background-color: var(--main-color);
  font-weight: 700;
  transition: 0.4s;
}

.bannerButton:hover {
  background-color: #091b3d;
  font-weight: 700;
}


@media only screen and (max-width: 450px) {

  .verify-addr-bg .content-container {
    padding-top: 5rem; /* Adjust as needed */
  }
  .displayTour {
    display: none;

  }

  .backgroudHomeImage {
    background-image: url("/public/assets/images/landing-page-banner.png");
    height: 300px;
  }

  .bannerButton {
    background-color: var(--main-color);
    font-weight: 500;
    transition: 0.4s;
    font-size: 7px !important;
  }

  .bannerButton:hover {
    background-color: #091b3d;
    font-weight: 500;
    font-size: 7px !important;
  }

  .bannerText {
    color: #000;
    /* font-size: 18px; */
  }
}

@media only screen and (max-width: 991px) {
  .pd-bn
  {
    padding-left: 11rem;
  }
  .divMarginLeft {
    margin-left: 0;
  }

  .hideBlock {
    display: none;
  }

  .bannerText2 {

    color: #000;
    font-size: 1.6rem;
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
  }

  .bannerButton {
    background-color: var(--main-color);
    font-weight: 700;
    transition: 0.4s;
    font-size: 13px;
  }

  .bannerButton:hover {
    background-color: #091b3d;
    font-weight: 700;
    font-size: 13px;
  }
}


@media only screen and (min-width: 992px) {
  .imgMarginLeft {
    margin-left: 0px;
  }

  .displayTour {
    display: none;

  }

  .hideBlockRes {
    display: none;
  }

  .bannerText {
    color: #000;
    font-size: 24px;
  }

  .bannerText2 {

    color: #000;
    font-size: 4.6rem;
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
  }
}

.faq-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.faq-container h2 {
  text-align: center;
  font-size: 28px;
  /* margin-bottom: 20px; */
  color: #333;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  cursor: pointer;
  padding: 15px 0;
  /* background-color: #f16262; */
}

.faq-answer {
  font-size: 16px;
  color: #555;
  /* padding: 0px 0px; */
  /* padding-top: 10px; */
  /* background-color: #e4eff3; */
}

.faq-icon {
  transition: transform 0.7s ease, color 0.7s ease;
}

.faq-icon.opened {
  transform: rotate(180deg);
  color: #34d399;
  /* Adjust this color if needed */
}

.testimonials-section {
  background-color: #f7f7f7;
  overflow: hidden;
}

.testimonial-slide {
  display: flex !important;
  justify-content: center;
  text-align: center;
}

.testimonial-images {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-block: 30px;
}

.testimonial-images img {
  width: 50px;
  margin: 10px;
  border-radius: 50%;
  cursor: pointer;
  filter: grayscale(100%);
  /* Apply black and white effect to inactive images */
  transition: filter 0.5s ease-in-out;
}

.testimonial-slide {
  filter: grayscale(100%);
  /* Apply black and white effect to inactive slides */
  transition: filter 0.5s ease-in-out;
}

/* Active Testimonial Slide */
.testimonial-slide.active {
  filter: grayscale(0%);
  /* Remove black and white effect for active slide */
}

.testimonial-images img.active {
  filter: grayscale(0%);
  /* Remove black and white effect for active image */
}

.testimonial-content {
  width: 50%;
}

.testimonial-text {
  width: 70%;
  margin: 0 auto;
  line-height: 1.8rem;
  font-size: 14px;
}

.testimonial-name {
  text-transform: uppercase;
  background: linear-gradient(to right, #3498db, #ff18ba);
  /* Gradient from blue to pink */
  -webkit-background-clip: text;
  /* Apply gradient to text */
  color: transparent;
  /* Set text color to transparent */
  display: inline-block;
  width: 70%;
  /* Prevent gradient bleeding to adjacent elements */
  transition: background-position 0.5s ease-in-out;
  background-size: 100% auto;
  margin: 0 auto;
}


.services-card {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 100px auto 140px auto;
}


.service-card {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  height: 220px;
  width: 340px;
  margin-right: 20px;
  margin-bottom: 0px;
  position: relative;
  transform-style: preserve-3d;
  perspective: 700px;
}

.front-card {
  position: absolute;
  display: inline-flex;
  /* justify-content: center; */
  align-items: center;
  width: 85%;
  height: 175%;
  backface-visibility: hidden;
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  background-image: linear-gradient(45deg, rgb(58, 58, 58), rgb(22, 22, 22));
  transition: 0.7s;
}

.back-card {
  position: absolute;
  color: whitesmoke;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 175%;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: rotateX(-180deg);

  background: linear-gradient(45deg, rgb(91 108 196), rgb(139 175 217));
  /* background-image: linear-gradient(45deg, rgb(36, 36, 36), rgb(71, 71, 71)); */
  transition: 0.7s;
  padding-inline: 30px;
}

.service-card:hover .back-card {
  transform: rotateY(0deg);
}

.front-card h4 {
  color: white;
  font-size: 22px;
  font-weight: 400;
  transform: translateZ(50px);
}

.back-card h3 {
  display: inline-block;
  margin: 50px auto 30px auto;
  transform: translateZ(50px);
  color: rgb(116, 116, 116);
}

.back-card p {
  transform: translateZ(50px);
}

.service1-card .front-card {
  background: linear-gradient(45deg, rgb(91 108 196), rgb(139 175 217));
}

.service2-card .front-card {
  background: linear-gradient(45deg, rgb(244 165 192), rgb(143 87 177));
}

.service3-card .front-card {
  background: linear-gradient(45deg, rgb(203 106 153), rgb(217 181 134));
}

.service-card:hover .front-card {
  transform: rotateY(180deg);
}




.editfundraiser {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}


.reactour__highlighted {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 193, 195, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(34, 193, 195, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(34, 193, 195, 0);
  }
}



.tour-button {
  transition: all 0.3s ease;
  /* For smooth transitions */
  font-size: 16px;
  /* Set the initial font size */
}

.tour-button:hover {
  /* background-color: #7d7b83; */
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.tour-button:hover span {
  font-size: 22px;
  /* Increase the text size on hover */
  color: #ffffff;
  /* Change the text color on hover */
  transition: font-size 0.3s ease, color 0.3s ease;
  /* Smooth transitions for text size and color */
}


@media (max-width: 1199px) {
  .email-campaign-tour-container {
    display: none !important;
  }

  .container1>div {
    flex: 1;
    /* Each div takes up equal space in one row */
  }
}

/* This shows the tour for screens 1200px and larger */
@media (min-width: 1200px) {

  .donation-boxBrowseFund {
    width: 90%; /* Ensure boxes take full width of grid columns */
    max-width: 400px; /* Prevent excessive width on large screens */
    margin: auto; /* Center boxes */
    background-color: #fff;
    border-radius: 20px;
    padding-bottom: 30px;
  }

  .padding-x{
    padding-left: 6rem;
    padding-right: 6rem;
  } 

  .textremSize {
    font-size: 8rem;
  }

  .divMarginLeft {
    margin-left: 0px;
  }

  .imgMarginLeft {
    margin-left: 100px;
  }

  .email-campaign-tour-container {
    display: block;
    /* Or flex if you want it to be a flexbox container */
  }
}


/* Container Styling */
.enquiry-form-container {
  background-color: rgba(255, 255, 255, 0.8);
  /* box-shadow: 0px 0px 10px 10px rgba(117, 114, 114, 0.255) !important; */
  /* z-index: 3; */
  border-radius: 10px;
  /* background-color: #f9f9f9; */
  color: rgb(14, 3, 3);
  padding: 30px;
  max-width: 700px;
  /* margin: 0 auto; */
  margin-left: 10px;
  text-align: center;
  /* border-radius: 15px; */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease-in-out;
}

/* .enquiry-form-container:hover {
  transform: scale(1.02);
} */

@media (max-width: 600px) {
  .verify-addr-bg .content-container {
    padding-top: 5rem; /* Adjust as needed */
  }
  .enquiry-form-container {
    padding: 20px;
    max-width: 100%;
  }
}

/* Title and description styling */
.enquiry-form-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
}

.enquiry-form-description {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #666;
}

@media (max-width: 600px) {
  .enquiry-form-title {
    font-size: 1.5rem;
  }

  .enquiry-form-description {
    font-size: 1rem;
  }
}

/* Main form styling */
.enquiry-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Horizontal arrangement for Name and Email */
.enquiry-form-input-container-horizontal {
  display: flex;
  gap: 15px;
}

/* Ensures the Name and Email fields take up half the space */
.enquiry-form-input-half {
  flex: 1;
}

/* For smaller screens, stack inputs vertically */
@media (max-width: 600px) {
  .enquiry-form-input-container-horizontal {
    flex-direction: column;
  }
}

/* Input and Textarea Styling with Animation */
.enquiry-form-input,
.enquiry-form-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 0.95rem;
  margin-top: 10px;
  transition: all 0.3s ease;
  background-color: #fafafa;
}

.enquiry-form-input:hover,
.enquiry-form-textarea:hover {
  border-bottom-color: #999;
}

.enquiry-form-input:focus,
.enquiry-form-textarea:focus {
  border-bottom-color: rgb(24, 23, 85);
  box-shadow: 0px 4px 6px rgba(240, 79, 35, 0.2);
  background-color: #fff;
  outline: none;
}

/* Full-width input on mobile screens */
@media (max-width: 600px) {
  .enquiry-form-input {
    font-size: 0.85rem;
    padding: 8px;
  }
}

/* reCAPTCHA and Submit button on one line */
.enquiry-form-captcha-and-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

/* Submit button styling */
.enquiry-form-button {
  flex: 1;
  padding: 8px;
  background-color: rgb(24, 23, 85);
  ;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px rgba(240, 79, 35, 0.2);
}

.enquiry-form-button:hover {
  background-color: #524b85;
  transform: translateY(-2px);
  box-shadow: 0px 7px 20px rgba(240, 79, 35, 0.4);
}

/* For smaller screens, make the button and captcha stack vertically */
@media (max-width: 600px) {
  .enquiry-form-captcha-and-submit {
    flex-direction: column;
  }
}

/* Error message styling */
.enquiry-form-error,
.enquiry-form-error-captcha {
  color: #f56565;
  margin-top: 5px;
  font-size: 0.875rem;
}

.video-section {
  position: relative;
  background: linear-gradient(135deg, #e0eaff, #f3f8ff);
  /* Soft gradient background */
  padding: 30px;
  border-radius: 20px;
}

.video-sectionUser {
  position: relative;
  background: linear-gradient(135deg, #e0eaff, #f3f8ff);
  /* Soft gradient background */
  padding-left: 20px;
  padding-right: 20px;
  padding: 10px;
  
}

.custom-video-frame {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border: 5px solid #f0f0f0;
  position: relative;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button::before {
  content: '';
  border-style: solid;
  border-width: 15px 0 15px 25px;
  border-color: transparent transparent transparent white;
}

.play-button:hover {
  background: rgba(0, 0, 0, 0.7);
  /* Darker background on hover */
}

video:hover+.play-button {
  transform: scale(1.2);
  /* Optional hover effect on play button */
}

/* .landing-page-banner-sectionPrabalta {
  background-image: url("/public/assets/images/b9.png");
  min-height: 80vh;
  background-size: cover;
  background-repeat: round;
  padding-inline: 100px;
  background-position: 0px -35px;
}

@media only screen and (min-width: 500px) {

  .landing-page-banner-sectionPrabalta {
    background-image: url("/public/assets/images/b9.png");
    min-height: 80vh;
    background-size: cover;
    background-repeat: round;
    padding-inline: 100px;
    background-position: 0px -35px;
  }

  .landing-page-banner-sectionPrabaltaMobile {
    display: none;
  }

}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .landing-page-banner-sectionPrabalta {
    display: none;
  }

  .landing-page-banner-sectionPrabaltaMobile {
    background-image: url("/public/assets/images/m1.png");
    min-height: 50vh;
    background-size: cover;
   
    padding-inline: 10px;
    background-position: 0px -35px;
    align-items: flex-end;
  }

  .mobileText {
    font-size: larger;
  }

  .banner-text-1m {
    font-size: 12px;
    color: #000;
  }

} */

/* Default for larger screens (laptop/desktop) */
.landing-page-banner-sectionPrabalta {
  background-image: url("/public/assets/images/b92.png");
  min-height: 80vh;
  background-size: cover;
  background-repeat: round;
  padding-inline: 100px;
  background-position: 0px -35px;
}

/* Laptop view */
@media only screen and (min-width: 1024px) {

  
.donation-info-text {
  color: #0049B7;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
}
  .landing-page-banner-sectionPrabalta {
    min-height: 90vh;
    background-size: cover;
    padding-inline: 100px;
  }

  .landing-page-banner-sectionPrabaltaMobile {
    display: none;
  }
}

/* Tablet view (768px - 1023px) */
@media only screen and (min-width: 768px) and (max-width: 820px) {

  .formStyleFormini{
    width:650px;
    margin-left: 4rem;
  }
  .pd-bn
  {
    padding-left: 9rem;
  }
   .padding-x{
    padding-left: 1rem;
    padding-right: 1rem;
  } 
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }
.banner-text-1{
  font-size: 13px;
}
}
@media only screen and (min-width: 820px) {
  .banner-text-1{
    font-size: 23px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {


  .fundraiser-banner-section {
    /* Ensure the container stays centered and aligned */
    display: flex;
    flex-direction: column; /* Stack the items vertically */
    justify-content: center;
    align-items: center;
  }

  .container {
    flex-direction: column; /* Make sure the image is on top and text below */
  }

 
  .md:py-20 {
    padding-top: 1rem;
    padding-bottom: 1rem; /* Reduce padding */
  }

  .pr-6 {
    padding-right: 0; /* Remove extra padding */
  }

  img.fundraiser-slider-box {
    width: 100%; /* Ensure image fills the container width */
  }

  .padding-x{
    padding-left: 1rem;
    padding-right: 1rem;
  } 
  .hideLeft{
    display:none;
  }
  .marginInlineStart
  {
    margin-inline-start: 7.5rem;
  }
  .custWidth{
    width: 30rem;
  }

  .landing-page-banner-sectionPrabalta {
    background-image: url("/public/assets/images/b92.png");
    min-height: 50vh;
    background-size: cover;
    background-repeat: round;
    padding-inline: 50px;
    /* background-position: center; */
  }

  .landing-page-banner-sectionPrabaltaMobile {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  .hide-lg
  {
    display:block;
  }
  
}
@media only screen and (max-width: 425px)  {
  .pd-bn
  {
    padding-left: 4rem;
  }
.mobileText {
  font-size: larger;
}
}
@media (min-width: 425px) and (max-width: 767px) {
  .mobileText {
    font-size: x-large;
  }
  }
/* Mobile view (320px - 767px) */
@media only screen and (min-width: 320px) and (max-width: 767px) {

  .services-card {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: 130px;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: 100px 20px 20px 20px;
  }
  .textremSize {
    font-size: 4rem;
  }

  .landing-page-banner-sectionPrabalta {
    display: none;
  }

  /* .landing-page-banner-sectionPrabaltaMobile {
    background-image: url("/public/assets/images/m1.png");
    min-height: 50vh;
    background-size: cover;
    padding-inline: 10px;
    background-position: 0px -35px;
    align-items: flex-end;
  } */

  .textContainerBg{
    background-color: #edf5fe;
  }

  

  .banner-text-1m {
    font-size: 12px;
    color: #000;
  }
}


.gfm-terms-container {


  /* padding: 20px;
   */
}

/* Styling for the content section */
.gfm-content {
  width: 100%;
}

.gfm-content h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.gfm-content h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.gfm-content p {
  font-size: 1rem;
  line-height: 1.8;
  margin-left: 20px;
  margin-bottom: 20px;
  padding-left: 50px;
}

/* Styling for individual sections */
.gfm-section {
  margin-bottom: 40px;
}

/* Styling for the table of contents */
.gfm-toc {

  position: sticky;
  top: 150px;
  /* This will make the ToC stick when scrolling begins */
  margin-left: 110px;
  border: 1px solid #c0b9b9;
  border-radius: 5px;
}

.gfm-toc h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}


.gfm-toc ul {
  list-style-type: none;
  padding: 10px;
}


.gfm-toc ul li {
  margin-bottom: 10px;
}

.gfm-toc ul li a {
  text-decoration: none;
  font-size: 1rem;
  color: #007bff;
  cursor: pointer;
}

.gfm-toc ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  
  .tabViewHide {
    display: none;
  }
  .hambuger {
    display: block;
  }
  .navWidth {
    width: 100%;
  }
}

@media (min-width: 1201px) {
  .pd-bn
  {
    padding-left: 2rem;
  }
  .tabViewHide {
    display: block;
  }
  .hambuger {
    display: none;
  }
  .navWidth {
    width: fit-content;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {

  .success-message {
    background-color: #fff;
    padding: 60px 120px;
    border-radius: 20px;
    border-color: #606060;
    z-index: 999;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .imgDim{

    width: 11rem;
    height: 15rem;

  }


.front-card {
  position: absolute;
  display: inline-flex;
  /* justify-content: center; */
  align-items: center;
  width: 106%;
  height: 145%;
  backface-visibility: hidden;  
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  background-image: linear-gradient(45deg, rgb(58, 58, 58), rgb(22, 22, 22));
  transition: 0.7s;
}

.back-card {
  position: absolute;
  color: whitesmoke;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106%;
  height: 145%;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: rotateX(-180deg);
  background: linear-gradient(45deg, rgb(91 108 196), rgb(139 175 217));
  /* background-image: linear-gradient(45deg, rgb(36, 36, 36), rgb(71, 71, 71)); */
  transition: 0.7s;
  padding-inline: 30px;
}

.margin-res{
   margin-top:  3rem;
}


  .login-bg {
    display: none;
  }


  .overlay {
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    height: 100vh;           /* Make sure it takes the full viewport height */
    display: flex;           /* Ensure it's a flex container */
    position: fixed;         /* Fix it to the viewport */
    top: 0;                  /* Reset positioning */
    left: 0;
    right: 0;
    bottom: 0;
  }
  .how-we-work-modal {
    position: absolute;  /* Makes the modal an absolute element */
    top: 60%;            /* Push it 50% down from the top */
    left: 50%;           /* Push it 50% from the left */
    transform: translate(-50%, -50%);  /* Adjust positioning to center perfectly */
    width: 80%;          /* Optional: Adjust the width if needed */
    max-width: 600px;    /* Optional: Set a max width for the modal */
    z-index: 1000;       /* Ensure it appears above other elements */
    background: white;   /* Optional: Set background color */
    padding: 20px;       /* Optional: Add padding for better spacing */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  }

  .hide-md-only {
    display: none;
  }

  .hide-md-lg {
    display: none;
  }
  .w-full-md-lg {
    width: 100%;
  }
  .leftMarg{
    margin-left: 50px;
  }
 
  .textremSize {
    font-size: 4rem;
  }

  


}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .mobileRecurring{
    padding: 30px;
    margin: 30px;
  }
}




@media (min-width: 1024px) {

  .grid-container {
    grid-template-columns: repeat(3, 1fr); /* Three columns on large screens */
  }

  .imageRecurring {
      width: 25%;
  }

  .imgDim{

    width: 16rem;
    height: 15rem;

  }
}


@media only screen and (min-width: 1430px){
  .inputWidth {
    width: 740px;
    place-content: center;
  }
  
  .imgDim{
  
    width: 18rem;
    height: 20rem;
  
  }
     
  }

.sidebar-button {
  display: flex;              /* Use flexbox for better alignment */
  align-items: center;       /* Center items vertically */
  justify-content: flex-start;/* Align text to the left */
  min-width: 220px;          /* Set a minimum width */
             
  text-align: left;          /* Ensure text is aligned to the left */
  border: none;              /* Remove default button border */
  background: none;          /* Remove default button background */
  cursor: pointer;           /*
   Change cursor to pointer */
}

.headerTop
{
  margin-bottom : 90px;
  padding-top: 110px;
}
.headerTop .inner {
  display: flex;
  justify-content: space-between;
  grid-column: main;
  margin-inline: auto;
  max-width: 1024px;
  width: 100%;
}

.headerTop .image {
  flex: none;
  margin: 168px 0 -50px 40px;
  width: clamp(280px, 31.11vw, 350px);
}


.header-banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  position: relative;
  background-color: #f5f5f5;
  height: 400px; /* Adjust the height as needed */
  overflow: hidden;
}

/* Styling for the text on the left */
.banner-text {
  margin-left: 20px;
  width: 50%; /* Adjust to control the width of the text area */
}

.banner-text h1 {
  font-size: 36px;
  margin: 0;
}

.banner-text p {
  font-size: 18px;
  margin-top: 10px;
}

/* Styling for the image on the right */
.banner-image {
  width: 50%;
  position: relative;
}

.banner-image img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(30%); /* This pushes 30% of the image below the banner */
}