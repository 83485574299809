/* General styling for modal overlay */
.modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    z-index: 50;
    padding: 16px;
  }
  
  /* Styling for modal content */
  .fade-in-bottom {
    animation: fadeInBottom 0.3s ease-out;
  }
  
  /* Keyframes for fade-in animation */
  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Styling for modal content on different devices */
  @media (max-width: 1024px) {
    /* For mobile, tablet, and iPad devices */
    .facebook-preview,
    .instagram-preview {
      display: none;
    }
  }
  
  /* Additional styling for modal content on mobile, tablet, and iPad */
  @media (max-width: 768px) {
    /* For mobile and tablet devices */
    .modal-overlay {
      flex-direction: column;
    }
  
    .facebook-preview,
    .instagram-preview {
      width: 100%;
      height: auto;
      max-height: 90vh;
    }
  }
  
  /* General styling for preview boxes */
  .preview-box {
    background: white;
    border-radius: 16px;
    width: 100%;
    max-width: 350px;
    height: 654px;
    max-height: 90vh;
    overflow: auto;
    margin: 8px 0;
  }
  
  .preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .preview-header h2 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .preview-close-btn {
    cursor: pointer;
  }
  
  .preview-content {
    padding: 16px;
  }
  
  .preview-post {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
  }
  
  .preview-post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .preview-post-header img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .preview-post-header .profile-info {
    display: flex;
    align-items: center;
  }
  
  .preview-post-header .profile-info p {
    font-weight: bold;
  }
  
  .preview-post-header .profile-info span {
    font-size: 0.875rem;
    color: #757575;
  }
  
  .preview-post-content {
    margin-top: 8px;
  }
  
  .preview-post-content img,
  .preview-post-content video {
    width: 100%;
    border-radius: 8px;
  }
  
  .preview-post-actions {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
    margin-top: 8px;
  }
  
  .preview-post-actions button {
    display: flex;
    align-items: center;
    color: #757575;
    font-size: 0.875rem;
  }
  
  .preview-post-actions button svg {
    margin-right: 4px;
  }
  
  @media (min-width: 1025px) {
    .facebook-preview,
    .instagram-preview {
      display: block;
    }
  }
  