/* Modal styles for increased width */
.onboarding-modal {
  width: 500px; 
  height: 700px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  background-color: white;
  padding: 20px;
}

.onboarding-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  text-align: center;
}


/* Onboarding image container */
.onboarding-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

/* Onboarding image with floating animation */
/* Onboarding image with floating animation */
.onboarding-image {
    max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto;
    animation: onboarding-float 4s ease-in-out infinite; /* Floating effect */
    transition: transform 0.3s ease-in-out; /* Smooth zoom on hover */
}

.onboarding-image-small {
    max-width: 200px; /* Decreased size */
    max-height: 200px; /* Decreased size */
    width: auto;
    height: auto;
    animation: onboarding-float 4s ease-in-out infinite; /* Floating effect */
    transition: transform 0.3s ease-in-out; /* Smooth zoom on hover */
}



/* Scale effect on hover */
.onboarding-image:hover {
    transform: scale(1.05); /* Slight zoom-in on hover */
}

/* Floating animation */
@keyframes onboarding-float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px); /* Move image up slightly */
    }
    100% {
        transform: translateY(0px); /* Move image back down */
    }
}

/* Welcome Message Styling */
/* Paragraph Styling */
.onboarding-paragraph {
    color: black !important; /* Set the text color to black */
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 25px; /* Add margin to create space between text and button */
}

/* Welcome Message (Heading) Styling */
.onboarding-welcome-message {
    color: #0E398F; /* Keep heading color */
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 15px; /* Add margin to create space below the heading */
}


/* Button Styling */
.onboarding-button {
    background-color: #0E398F !important; /* Set the background to #0E398F */
    border: 2px solid #0E398F; /* Border in #0E398F */
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1rem;
    color: white; /* Button text in white */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Button hover effect */
.onboarding-button:hover {
    background-color: white; /* Change background to white on hover */
    color: white; /* Keep text color white */
    border-color: #0E398F; /* Keep border color as #0E398F */
    box-shadow: 0px 0px 10px 2px rgba(14, 57, 143, 0.5); /* Add a subtle shadow for hover effect */
}

/* Keyframes for bounce-in and bounce-out */
@keyframes onboarding-bounce-in-bottom {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    50% {
        opacity: 1;
        transform: translateY(-30px);
    }
    70% {
        transform: translateY(15px);
    }
    85% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes onboarding-bounce-out-bottom {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    30% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}

.onboarding-bounce-in-bottom {
    animation: onboarding-bounce-in-bottom 0.8s ease forwards;
}

.onboarding-bounce-out-bottom {
    animation: onboarding-bounce-out-bottom 0.8s ease forwards;
}

/* Scrollable Content Container */
/* Scrollable Content Container */
.scrollable-content {
    max-height: 300px; /* Set a height limit */
    overflow-y: scroll; /* Enable scrolling but hide the scrollbar */
    padding-right: 15px; /* Add space for the scroll bar */
  
    /* Hide scrollbar for WebKit-based browsers */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .scrollable-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  /* Scroll Button Styles */
  .scroll-button {
    position: fixed;
    bottom: 5%;
    right: 5%;
    background-color: #0E398F;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .scroll-button:hover {
    background-color: #143d8c;
    transform: scale(1.1);
  }

  /* Slide-in from the bottom animation */
@keyframes slide-in-bottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply the animation */
  .slide-in-bottom {
    animation: slide-in-bottom 0.7s ease forwards;
  }

  /* Progress Bar Container */
  .progress-bar-container {
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
  }
  
  /* Progress Bar itself */
 
.progress-bar {
  height: 100%;
  background-color: #0E398F;
  transition: width 0.8s ease;
  border-radius: 5px;
  position: absolute;
}

  @keyframes slide-in-right {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Apply the animation */
  .slide-in-right {
    animation: slide-in-right 0.7s ease forwards;
  }

   /* Slide-in from the right animation for the entire modal */
   @keyframes modal-slide-in-right {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Apply the animation to the modal */
  .modal-slide-in-right {
    animation: modal-slide-in-right 0.7s ease forwards;
  }

/* Zig-zag right-left bounce effect */
@keyframes modal-zig-zag-right {
  0% {
    opacity: 0;
    transform: translateX(100%); /* Start off-screen on the right */
  }
  20% {
    opacity: 1;
    transform: translateX(-10%); /* Swing left */
  }
  40% {
    transform: translateX(5%); /* Swing back to the right */
  }
  60% {
    transform: translateX(-3%); /* Slight left movement */
  }
  80% {
    transform: translateX(1%); /* Very slight right movement */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Settle into final position */
  }
}
  
  /* Apply the animation to the modal */
  .modal-zig-zag-right {
    animation: modal-zig-zag-right 1s ease forwards;
  }

/* Container for Next and Skip buttons */
.button-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  align-items: center;      /* Center the buttons vertically */
  margin-top: 20px;         /* Add margin for spacing */
  width: 100%;              /* Ensure buttons stay inside the modal */
}
  
  /* Styling for both the Next and Skip buttons */
  .next-button, .skip-button {
    background-color: #0E398F; /* Same color for both buttons */
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  /* Hover effect for both buttons */
  .next-button:hover, .skip-button:hover {
    background-color: #143d8c; /* Darker shade of blue on hover */
    box-shadow: 0 6px 15px rgba(14, 57, 143, 0.5);
    transform: translateY(-3px);
  }
/* General form styling */
.basic-info-form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap to reduce space between input fields */
  width: 100%;
  padding: 0 20px;
  align-items: center; /* Center the form elements */
}

/* Input Field Styling */
.basic-info-form input {
  width: calc(100% - -100px); /* Increase width to be slightly less than the modal width */
  max-width: 600px; /* Set a larger maximum width */
  padding: 12px; /* Adjust padding for more space inside */
  border-radius: 4px; /* Slightly reduced border-radius */
  border: 1px solid #ccc; /* Light border */
  font-size: 1rem; /* Font size */
  background-color: #f9f9f9; /* Light background */
  color: #333; /* Text color */
  box-shadow: none; /* No shadow */
  outline: none; /* Remove default outline */
  transition: border-color 0.2s ease; /* Smooth transition for focus */
}

/* Input Focus Effect */
.basic-info-form input:focus {
  border-color: #0E398F; /* Highlight border color on focus */
  background-color: #fff; /* Lighter background on focus */
  box-shadow: 0 0 4px rgba(14, 57, 143, 0.3); /* Subtle shadow on focus */
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto; /* Ensure buttons are pushed to the bottom */
  padding: 10px 20px;
  width: 100%; /* Ensure buttons stay inside the modal */
}

/* Button Styling */
/* Button Styling */
.onboarding-button {
  background-color: #0E398F;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Button Hover Effect */
.onboarding-button:hover {
  background-color: #143d8c;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Onboarding Modal Upload Box Styles */
.onboarding-modal .upload-box {
  border-color: gray;
  box-shadow: none;
  border-radius: 4px;
  border-style: dashed;
  border-width: 2px;
  padding: 50px 100px; /* Increased padding */
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.onboarding-modal .upload-box img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.onboarding-modal .upload-box p {
  font-size: 14px;
  text-align: center;
}

/* Uploaded Image Preview */
.onboarding-modal .uploaded-image-preview {
  margin-top: 20px;
}

.onboarding-modal .uploaded-image-preview img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 4px;
}

.onboarding-modal {
  width: 500px; 
  height: 650px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  background-color: #f7faf9;
  padding: 20px; /* Added padding inside the modal */
}

.onboarding-modal {
  width: 500px; 
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  background-color: #f7faf9;
  padding: 20px; /* Added padding inside the modal */
}


/* Content inside modal is centered */
.onboarding-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%; 
  text-align: center; 
}

/* Social media connect styles */
.social-connect-wrapper {
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
align-items: center;
}

.social-item {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
max-width: 400px;
background-color: white;
padding: 10px 20px;
border-radius: 8px;
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.social-details {
flex-grow: 1;
margin-left: 20px;
}

.social-details h3 {
font-size: 1.2rem;
margin-bottom: 0;
}

.social-details p {
font-size: 0.9rem;
color: gray;
}

.connect-button {
background-color: #0E398F;
color: white;
border: none;
padding: 10px 20px;
border-radius: 8px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.connect-button:hover {
background-color: #143d8c;
}

/* Reduce the size of the success image */
.success-icon {
  max-width: 320px; /* Adjust the width */
  height: auto;     /* Maintain the aspect ratio */
  margin-bottom: 20px; /* Add some margin for spacing */
}

/* Center the Get Started button */
.get-started-button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;      /* Center vertically */
  width: 100%;
  margin-top: 20px; /* Add margin to separate from other elements */
}

/* Center the Close button */
.close-button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;      /* Center vertically */
  width: 100%;
  margin-top: 20px; /* Add margin to separate from other elements */
}

/* Button styling (shared by both buttons) */
.centered-button {
  background-color: #0E398F;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for the buttons */
.centered-button:hover {
  background-color: #143d8c;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Increase the size of the Social Media Management image */
.social-media-management-image {
  max-width: 300px; /* Increase width */
  height: auto;     /* Maintain aspect ratio */
  margin-bottom: 20px; /* Optional: Add margin for spacing */
  transition: transform 0.3s ease-in-out; /* Smooth zoom on hover */
}

/* Optional: Add hover effect */
.social-media-management-image:hover {
  transform: scale(1.05); /* Slight zoom-in on hover */
}


/* Right swipe animation */
@keyframes swipe-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes swipe-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade out for swipe transition */
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Add swipe transition classes */
.swipe-right {
  animation: swipe-right 0.7s ease forwards;
}

.swipe-left {
  animation: swipe-left 0.7s ease forwards;
}

.fade-out {
  animation: fade-out 0.3s ease forwards;
}

/* Right swipe animation */
@keyframes modal-swipe-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes modal-swipe-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/* Fade out for swipe transition */
@keyframes modal-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Add swipe transition classes */
.modal-swipe-right {
  animation: modal-swipe-right 0.7s ease forwards;
}

.modal-swipe-left {
  animation: modal-swipe-left 0.7s ease forwards;
}

.modal-fade-out {
  animation: modal-fade-out 0.3s ease forwards;
}

